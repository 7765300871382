<template>
  <div class="page-container">
    <v-app>
    <md-app>
      <md-app-toolbar
        class="md-primary"
        md-elevation="0"
        v-if="
          this.$router.currentRoute.name !== 'Login' &&
          this.$router.currentRoute.name !== 'Validación' &&
          this.$router.currentRoute.name !== 'Inscríbete' &&
          this.$router.currentRoute.name !== 'Error'
        "
      >
        <md-button
          class="md-icon-button"
          @click="toggleMenu"
          v-if="!menuVisible"
        >
          <md-icon>menu</md-icon>
        </md-button>
        <!-- <span class="md-title md-alignment-left fw500">{{ $route.name }}</span> -->
        <span
          ><img
            alt="Acciona logo"
            width="80px"
            class="m-1"
            src="./assets/acciona-logo.svg"
          />
        </span>
        <div class="demo-badge">
          <span class="md-body-2 color6 md-alignment-right flex-right">
            Hola,
            {{
              this.authService.getDecodedJwtToken(this.$store.state.auth.user)
                .sub
            }}</span
          >
          <a
            href="javascript:;"
            @click="logout"
            class="md-body-1 md-alignment-right flex-right"
            >Cerrar sesión</a
          >
        </div>
        <div>
          <md-button to="/" class="md-icon-button">
            <md-avatar class="md-avatar-icon">{{userChar}}</md-avatar>
          </md-button>
        </div>
      </md-app-toolbar>
      <md-app-drawer
        :md-active.sync="menuVisible"
        md-persistent="mini"
        v-if="
          this.$router.currentRoute.name !== 'Login' &&
          this.$router.currentRoute.name !== 'Validación' &&
          this.$router.currentRoute.name !== 'Error' &&
          this.$router.currentRoute.name !== 'Inscríbete'
        "
      >
        <md-toolbar class="md-transparent" md-elevation="0">
          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>
        <div class="list">
          <md-list class="md-double-line">
            <md-list-item class="sidebar-user">
              <md-avatar :class="avatarClass">
                {{userChar}}</md-avatar>
              <div class="md-list-item-text">
                <span>{{this.authService.getDecodedJwtToken(this.$store.state.auth.user)
                .sub}}</span>
                <a href="javascript:;" @click="logout">cerrar sesión</a>
              </div>
            </md-list-item>
            <md-list-item v-if="this.menuVisible" class="sidebar-project">
              <md-icon class="small-margin">{{this.$store.state.projectIcon}}</md-icon>
              <div class="md-list-item-text">
                <a href="javascript:;" @click="toggleExpand" class="md-title"
                  >{{this.$store.state.project}}</a
                >
              </div>
            </md-list-item>
            <!-- <md-subheader>Notifications</md-subheader> -->
            <md-list-item
            v-if="this.$store.state.project"
              md-expand
              :md-expanded.sync="expandNews"
              @click="checkCollapse()"
              class="sidebar-project-selector"
            >
              <md-icon v-if="!this.menuVisible">{{this.$store.state.projectIcon}}</md-icon>
              <span class="md-list-item-text">Seleccionar proyecto</span>

              <md-list slot="md-expand">
                <project-selection-nav></project-selection-nav>
              </md-list>

            </md-list-item>
          </md-list>
        </div>
        <md-divider></md-divider>
        <div class="list">
          <md-list>
            <md-list-item to="/dashboard">
              <md-icon >dashboard</md-icon>
              <span class="md-list-item-text">Dashboard</span>
              <!-- <md-badge class="md-primary md-square" md-content="6" /> -->
            </md-list-item>
            <md-list-item to="/tablon-noticias">
              <md-icon >newspaper</md-icon>
              <span class="md-list-item-text">Tablón de Noticias</span>
            </md-list-item>
            <md-list-item to="/estadisticas">
              <md-icon>insights</md-icon>
              <span class="md-list-item-text">Estadísticas</span>
            </md-list-item>
          </md-list>
        </div>
        <md-divider
          v-if="
            this.authService.getDecodedJwtToken(this.$store.state.auth.user)
              .rol === 'ROLE_SUPER_ADMIN'
          "
        ></md-divider>
        <div class="list">
          <md-list
            v-if="
              this.authService.getDecodedJwtToken(this.$store.state.auth.user)
                .rol === 'ROLE_SUPER_ADMIN'
            "
          >
            <md-subheader v-if="this.menuVisible"
              >Gestión de Usuarios</md-subheader
            >
            <md-list-item to="/gestion-usuarios">
              <md-icon>manage_accounts</md-icon>
              <span class="md-list-item-text">Panel de usuarios</span>
            </md-list-item>
            <md-list-item to="/actividad">
              <md-icon>history</md-icon>
              <span class="md-list-item-text">Actividad</span>
            </md-list-item>
          </md-list>
        </div>
      </md-app-drawer>
      <md-app-content
        v-if="
          this.$router.currentRoute.name !== 'Login' &&
          this.$router.currentRoute.name !== 'Validación' &&
          this.$router.currentRoute.name !== 'Error' &&
          this.$router.currentRoute.name !== 'Inscríbete'
        "
      >
        <router-view>
        </router-view>
      </md-app-content>
      <md-app-content
        class="public-app-content"
        v-if="
          this.$router.currentRoute.name == 'Login' ||
          this.$router.currentRoute.name == 'Validación' ||
          this.$router.currentRoute.name == 'Error' ||
          this.$router.currentRoute.name == 'Inscríbete'
        "
      >
        <router-view>
        </router-view>
      </md-app-content>
    </md-app>
    </v-app>
  </div>
</template>

<script>
import ProjectSelectionNav from './components/Nav/ProjectSelectionNav.vue';
// import auth from './store/auth.module';

// eslint-disable-next-line no-unused-vars
import authService from './services/auth.service';

export default {
  components: {
    ProjectSelectionNav,
  },
  data: () => ({
    username: null,
    menuVisible: false,
    expandSingle: true,
    expandNews: false,
    authService,
    avatarClass: 'md-avatar-icon md-small',
    userrole: null,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: 'breadcrumbs_dashboard',
      },
      {
        text: 'Link 1',
        disabled: false,
        href: 'breadcrumbs_link_1',
      },
      {
        text: 'Link 2',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
  }),
  computed: {
    userChar() {
      if (this.authService.getDecodedJwtToken(this.$store.state.auth.user)) {
        const name = this.authService.getDecodedJwtToken(this.$store.state.auth.user).sub;
        return name.substring(1, 0).toUpperCase();
      }
      return 'A';
    },
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.commit('clearProject');
      this.$router.push('/login');
    },
    checkCollapse() {
      if (!this.menuVisible) {
        this.toggleMenu();
      }
    },
    toggleExpand() {
      this.expandNews = !this.expandNews;
    },
  },

  watch: {
    menuVisible(val) {
      if (val) {
        this.avatarClass = 'md-avatar-icon';
      } else {
        this.avatarClass = 'md-avatar-icon md-small';
        this.expandNews = false;
      }
    },
  },
};
</script>

<style>
.public-app-content {
  padding: 0;
}
.md-app {
  min-height: 100vh;
  border: 1px solid rgba(#000, 0.12);
}

.md-list-item-content {
  justify-content: flex-start;
  padding: 0 16px;
}
.doc-list-item {
  color: grey;
}

.md-drawer {
  width: 33%;
  max-width: 256px;
}

.demo-badge {
  margin-left: auto;
  margin-right: 20px;
}
.md-icon.md-size-0-5x {
  width: 24px;
  min-width: 24px;
  height: 24px;
  font-size: 15px !important;
}

.md-list.md-theme-default .md-selected .md-list-item-content,
.md-list.md-theme-default .router-link-active .md-list-item-content {
  color: #ff0000;
}
.mm-titlePage {
  border-bottom: 1px solid #ccc !important;
  padding: 0 0 12px 0;
  margin: 0 30px;
  width: -webkit-fill-available;
}

.md-card-content:last-of-type {
  padding-bottom: 16px;
}

.md-card [data-background-color="green"] {
  background: linear-gradient(
    60deg,
    rgb(92, 155, 111, 0.8),
    rgb(88, 201, 88, 0.8)
  );
  -webkit-box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
  box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
}
.md-card [data-background-color="acciona"] {
  background: linear-gradient(
    60deg,
    rgba(255, 0, 0, 0.8),
    rgba(141, 15, 15, 0.8)
  );
  -webkit-box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
  box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
}
.md-card [data-background-color="acciona-light"] {
  background: linear-gradient(
    60deg,
    rgba(122, 122, 122, 0.8),
    rgba(56, 56, 56, 0.8)
  );
  -webkit-box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
  box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
}
.md-card [data-background-color="acciona-dark"] {
  background: linear-gradient(60deg, #557a91b9, #2c4f63);
  -webkit-box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
  box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
}
.md-card [data-background-color="blue"] {
  background: linear-gradient(
    60deg,
    rgb(93, 159, 161, 0.8),
    rgb(97, 222, 226, 0.8)
  );
  -webkit-box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
  box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
}
.md-card [data-background-color="orange"] {
  background: linear-gradient(
    60deg,
    rgba(204, 164, 89, 0.8),
    rgb(247, 169, 24, 0.8)
  );
  -webkit-box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
  box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
}
.md-card [data-background-color="red"] {
  background: linear-gradient(
    60deg,
    rgba(204, 102, 89, 0.8),
    rgba(247, 76, 24, 0.8)
  );
  -webkit-box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
  box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
}
.md-tabs.md-theme-default .md-tabs-navigation {
  background-color: rgba(0, 0, 0, 0.06);
}

.md-card {
  display: inline-block;
  position: relative;
  width: -webkit-fill-available;
  margin: 25px 0;
  overflow: unset;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
}

.md-card .md-card-header {
  box-shadow: 0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
  margin: -20px 15px 0;
  border-radius: 3px;
  padding: 15px;
  color: rgba(0, 0, 0, 0.87);
}

.md-card img {
  width: 100%;
  height: auto;
}

.md-card [data-background-color="masma"] {
  background: linear-gradient(60deg, #004a38, #8ae69a);
  -webkit-box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
  box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%),
    0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
}

.md-field .md-input,
.md-field .md-textarea {
  height: 36px;
  padding: 7px 0;
  font-size: 14px !important;
  -webkit-text-fill-color: #495057 !important;
}

.md-field .md-input,
.md-field .md-textarea {
  height: 32px;
  padding: 0;
  display: block;
  flex: 1;
  border: none;
  background: none;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: font-size, padding-top, color;
  font-family: inherit;
  font-size: 16px;
  line-height: 32px;
}
.md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active {
  font-size: 110%;
  color: var(--md-theme-default-primary-on-background, #222324);
}
.md-tabs.md-alignment-fixed .md-tabs-navigation .md-button {
  max-width: 364px;
}
.md-tabs.md-theme-default .md-tabs-indicator {
  background-color: #004a38;
  background-color: var(--md-theme-default-primary-on-background, #222324);
}
.md-tabs-container {
  padding: 0;
}
.md-field.md-theme-default.md-disabled:after {
  background-image: none;
}
.md-field.md-theme-default.md-focused > .md-icon {
  color: #ff0000;
  fill: #ff0000;
}
.md-field.md-theme-default.md-focused label {
  color: #ff0000;
}
.md-field.md-theme-default:before {
  background-color: #ff0000;
}
.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
  border-color: #004a38;
}
.md-card-profile {
  text-align: center;
}
</style>
<style lang="scss">
// * {
//   border: solid 0.5px blue;
// }
.md-content.md-app-content.md-flex.md-theme-default{
  background-color: #F9F9FF;
}

.md-alignment-right {
  text-align: right;
  // display: block;
}
.md-list-item-expand {
  border-bottom: none;
  border-top: none;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.small-margin {
  margin-right: 0.2em !important;
}
.md-list.md-double-line .md-list-item-content {
  min-height: 48px;
}
.md-list.md-double-line .sidebar-project .md-list-item-content {
  min-height: 30px;
}
.md-list.md-double-line .sidebar-project-selector .md-list-item-content {
  min-height: 48px;
}
.md-persistent-mini.md-active {
  .sidebar-user {
    margin-bottom: 3em;
  }
  .md-list.md-double-line .sidebar-project-selector .md-list-item-content {
    min-height: 40px;
    .md-list-item-text {
      padding-left: 5px;
    }
  }
}
.demo-badge {
  span {
    line-height: 14px;
  }
}
.md-drawer:not(.md-active){
  transform: translate3D(0,48px,0) !important;
}
.md-drawer.md-persistent-mini:not(.md-active) .md-list-item-content {
  padding: 0 13px;
}
.md-drawer.md-persistent-mini:not(.md-active) {
  width: 50px !important;
}
.md-toolbar .md-button .md-ripple {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
}
.md-toolbar {
  padding: 0 13px;
}
.md-toolbar-section-end .md-icon-button .md-ripple{
justify-content: center;
}
.md-toolbar{
  position:sticky;
  top:0px;
  right:0;
  min-height: 48px;
}
.v-application ol, .v-application ul {
    padding-left: 0;
}
.md-list.md-theme-default .md-selected .md-list-item-content,
.md-list.md-theme-default .router-link-active .md-list-item-content i {
    color: #ff0000;
}
</style>
