<template>
  <div>
    <canvas id="role-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'Role-Chart',

  data() {
    return {
      chartObj: null,
      roleData: {
        totalUser: 0,
        totalSuperAdmin: 0,
      },
      roleChartData: {
        type: 'doughnut',
        data: {
          labels: ['ROLE_USER', 'ROLE_SUPER_ADMIN'],
          datasets: [
            {
              label: 'Total de usuarios',
              data: [0, 0],
              backgroundColor: [
                '#5D5FEF',
                '#1DE9B6',
              ],
              borderColor: '#fff',
              borderWidth: 0,
            },

          ],
        },
        options: {
          layout: {
            padding: {
              left: 30,
              right: 30,
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
          responsive: true,
          autoPadding: false,
          lineTension: 1,
          scales: {

          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById('role-chart');
    this.roleChartData.data.datasets[0].data = [
      this.roleData.totalUser,
      this.roleData.totalSuperAdmin,
    ];
    // eslint-disable-next-line no-new
    this.charObj = new Chart(ctx, this.roleChartData);
  },

  methods: {
    updateRoleData(val) {
      this.roleData.totalUser = val.totalUser;
      this.roleData.totalSuperAdmin = val.totalSuperAdmin;

      this.roleChartData.data.datasets[0].data = [
        this.roleData.totalUser,
        this.roleData.totalSuperAdmin,
      ];
      this.charObj.update();
    },
  },
};
</script>
