<template>
  <div>
    <div class="headerLogin">
      <div
        class="
          separator separator-bottom separator-skew
          zindex-100
          headerSeparatorLogin
        "
      >
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          style="display: n"
        >
          <polygon
            points="2560 0 2560 100 0 100"
            class="fill-default"
          ></polygon>
        </svg>
      </div>
    </div>
    <md-card class="inputBoxLogin">
      <div class="login-card-header">
        <span
          ><img
            alt="Acciona logo"
            width="80px"
            class="m-2"
            src="../assets/acciona-logo.svg"
          />
        </span>
        <div class="text-center text-muted mt-2 mb-3">
          <small>Validación de cuenta de usuario</small>
          <h6 class="val-code mt-2 mb-4">{{this.$route.query.cod}}</h6>
        </div>
        <hr class="my-4" />
      </div>
      <md-content>
        <div class="text-center text-muted mt-2 mb-1">
          <small>Introduce tu contraseña y</small>
          <small>estarás listo para empezar a usar tu cuenta:</small>
        </div>
        <div>
          <user-data-register
            @updateUser="updateValidationUser"
          ></user-data-register>
          <div class="btn-login login_user-btn mb-2">
            <v-btn color="red lighten-1" dark block @click="validateUser">
              <v-icon left>start</v-icon>
              Comenzar
            </v-btn>
          </div>

        </div>
      </md-content>
    </md-card>
  </div>
</template>

<script>
import UserDataRegister from '../components/Register/User/UserDataRegister.vue';

import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'user-register',
  data: () => ({
    user: {
      name: null,
      password: null,
    },
  }),
  components: {
    UserDataRegister,
  },
  methods: {
    updateValidationUser(val) {
      this.user = val;
    },
    validateUser() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}users`,
          {
            username: this.user.name,
            password: this.user.password,
            validationCode: this.$route.query.cod,
            affiliateId: this.$route.query.id,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.$router.push('/login');
        })
        .catch(() => {
          this.$router.push('/error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.md-app-content {
  padding: 0;
}
.inputBoxLogin {
  display: block;
  width: 40%;
  margin-left: auto !important;
  margin-right: auto !important;
  border-style: none;
  border-radius: 10px;
  padding: 2rem 3rem;
  border-color: #004a38;
  margin-top: -25vh;
  max-width: 600px;
  min-width: 350px;
}
.val-button-container{
  justify-content: center;
    display: flex;
}
.md-card  {
  box-shadow: 0 10px 30px -12px rgb(0 0 0 / 22%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}

</style>
