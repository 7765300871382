<template>
  <div>
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="items">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="cantidad-noticias-container">
      <div class="dashboard_box small-box box-1 two-col one-row noticias-box">
        <v-icon color="red lighten-1" x-large>mdi-newspaper</v-icon>
        <span class="noticias-total">{{ newsLastMonth }}</span>
        <span class="noticias-text">Noticias en el mes actual</span>
      </div>
      <div class="dashboard_box small-box box-1 two-col one-row noticias-box">
        <v-icon color="red lighten-1" x-large>mdi-newspaper</v-icon>
        <span class="noticias-total">{{ newsLastYear }}</span>
        <span class="noticias-text">En los últimos 12 meses</span>
      </div>
      <div class="dashboard_box small-box box-1 two-col one-row noticias-box">
        <v-icon color="red lighten-1" x-large>mdi-newspaper</v-icon>
        <span class="noticias-total">{{ totalNoticias }}</span>
        <span class="noticias-text">Noticias totales</span>
      </div>
    </div>
    <div class="charts-grid">
      <div class="chart-noticias">
        <div class="dashboard_box tabs">
          <v-tabs background-color="transparent" grow color="red lighten-1">
            <v-tab> Últimos 12 meses </v-tab>
          </v-tabs>
        </div>
        <div class="dashboard_box chart" id="HistoricChart">
          <HistoricChart ref="HistoricChart" />
        </div>
      </div>
      <div class="chart-evaluadas">
        <div class="dashboard_box tabs">
          <v-tabs background-color="transparent" color="red lighten-1" grow>
            <v-tab>Evaluadas</v-tab>
          </v-tabs>
        </div>
        <div class="dashboard_box chart" id="chartInscription">
          <LabeledChart ref="labeledChart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HistoricChart from '../../components/Charts/HistoricChart.vue';
import LabeledChart from '../../components/Charts/LabeledChart.vue';

import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';

const axios = require('axios');

export default {
  name: 'statistics-panel',
  data() {
    return {
      items: [
        {
          text: 'Proyectos',
          disabled: false,
          to: '/seleccionar-proyecto',
        },
        {
          text: '',
          disabled: false,
          to: '/dashboard',
        },
        {
          text: 'Estadísticas',
          disabled: true,
          to: '#',
        },
      ],

      inscriptionData: {
        totalNoticias: 0,
      },
      labeledData: {
        totalEtiquetadas: 0,
        totalNoEtiquetadas: 0,
      },
      totalNoticias: 0,
      newsHistoricData: {
        totalNoticias: [],
      },
      info: null,
      loading: true,
      errored: false,
      userId: null,
      userRole: null,
      userName: null,
      user: [],
    };
  },
  computed: {
    newsLastYear() {
      return this.newsHistoricData.totalNoticias.reduce((a, b) => a + b, 0);
    },
    newsLastMonth() {
      const currentMonth = new Date().getMonth();
      return this.newsHistoricData.totalNoticias[currentMonth];
    },
  },
  components: {
    HistoricChart,
    LabeledChart,
  },
  mounted() {
    if (this.$store.state.project != null) {
      this.items[1].text = this.$store.state.project;
      this.userId = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).affiliate;
      this.userRole = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).rol;
      this.userName = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).sub;
      this.loadData();
    } else {
      this.$router.push('/seleccionar-proyecto');
    }
  },
  methods: {
    loadData() {
      this.loadInscriptionTypeData();
      this.loadNewsHistoricData();
    },
    getRequestParams() {
      const params = {};
      params.projectId = this.$store.state.projectId;
      return params;
    },

    loadInscriptionTypeData() {
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/stats/count`, {
          headers: authHeader(),
          params: this.getRequestParams(),
        })
        .then((response) => {
          this.labeledData.totalEtiquetadas = 0;
          this.labeledData.totalNoEtiquetadas = 0;

          this.totalNoticias = response.data[0].total.num;
          this.labeledData.totalEtiquetadas = response.data[0].labeled.num;
          this.labeledData.totalNoEtiquetadas = this.totalNoticias - response.data[0].labeled.num;

          this.$refs.labeledChart.updateLabeledData(this.labeledData);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadNewsHistoricData() {
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/stats/date/`, {
          headers: authHeader(),
          params: this.getRequestParams(),
        })
        .then((response) => {
          this.newsHistoricData.totalNoticias = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ];

          response.data.forEach((element) => {
            // eslint-disable-next-line no-underscore-dangle
            const claveMonth = element._id.month;
            const valor = element.count;
            if (claveMonth) {
              this.newsHistoricData.totalNoticias[claveMonth - 1] = valor;
            }
          });

          this.$refs.HistoricChart.updateHistoricInscriptionData(
            this.newsHistoricData,
          );
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.item {
  margin-bottom: 2rem;
}
.card-filter-button {
  padding: 0 10px;
  display: flex;
  background-color: #fff;
  border: none;
}
.card-filter-button:hover {
  background-color: #fff;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}
</style>
<style lang="scss" scoped>
.charts-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1em;
  height: 100%;
}
.dashboard_box.tabs {
  margin-bottom: 1em;
}

.noticias-box {
  padding: 1em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;

  .noticias-total {
    margin-top: 0.5em;
    font-size: 1.71em;
    color: #e57373;
  }
  .noticias-text {
    line-height: 1.1;
    // max-width: 8em;
    text-align: center;
  }
}
.chart-noticias {
  grid-column: 1 / span 8;
  height: 100%;

  .chart {
    padding: 1em;
  }
}
.chart-evaluadas {
  grid-column: 9 / span 4;
  height: 100%;

  .chart {
    padding: 2em;
  }
}
.cantidad-noticias-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1em;
  & > div {
    grid-column: span 4;
    min-height: 150px;
  }
  // flex-direction: column;
  // justify-content: space-between;
  // align-content:space-between;
}
// * {
//   border: solid 1px blue;
// }
</style>
