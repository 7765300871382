<template>
  <div>
    <div id="map-container"></div>
    <div class="loader-overlay" v-if="loading">
      <v-progress-circular
        color="red lighten-1"
        class="circular-progress"
        size="50"
        width="5"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';

export default {
  name: 'NewsMap',
  data() {
    return {
      stats: {
        totalNews: 0,
        chartLoaded: false,
      },
      svg: null,
      projection: null,
      zoom: null,
      lastZoom: null,
      features: [],
      count: 0,
      loading: false,
      filter: {
        prediction: null,
        ubicacion: null,
        startDate: null,
        minProb: null,
        maxProb: null,
      },
      clickedNews: null,
      cancelTokenSource: null,
    };
  },

  mounted() {
    /* eslint-disable no-unused-vars */
    /* eslint-disable no-underscore-dangle */
    const mapBox = document.querySelector('.map-box');
    let width = mapBox.offsetWidth;
    let height = mapBox.offsetHeight;

    window.addEventListener('resize', () => {
      console.log('resize');
      width = mapBox.offsetWidth;
      height = mapBox.offsetHeight;
      this.svg.attr('width', width).attr('height', height);
    });

    this.svg = d3
      .select('#map-container')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('cursor', 'grab')
      .on('mousedown', () => {
        this.svg.style('cursor', 'grabbing');
      });

    // Map and projection
    const translateX = width / 7 - 1000 / 7;
    this.projection = d3
      .geoMercator()
      .center([-translateX, 20]) // GPS of location to zoom on
      .scale(189);

    Promise.all([
      d3.json('/json/world.geojson'),

    ]).then((initialize) => {
      const dataGeo = initialize[0];

      this.svg
        .selectAll('path')
        .data(dataGeo.features)
        .join('path')
        .attr('fill', '#b8b8b8')
        .attr('d', d3.geoPath().projection(this.projection))
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .style('opacity', 0.3);

      this.zoom = d3
        .zoom()
        .scaleExtent([1, 8])
        .on('zoom', (event) => {
          this.lastZoom = event;
          this.svg.selectAll('path').attr('transform', event.transform);
          this.svg.selectAll('circle').attr('transform', event.transform);
        })
        .on('start', () => {
          this.svg.style('cursor', 'grabbing');
        })
        .on('end', () => {
          this.svg.style('cursor', 'grab');
        });
      this.svg.call(this.zoom);
      this.svg.on('wheel.zoom', null);
      this.stats.chartLoaded = true;
      this.$emit('updateChartLoaded', this.stats.chartLoaded);

      // d3.select(window).on('resize', resize);
    });
  },
  methods: {
    updateFilter(val) {
      this.filter = val;
      this.getNoticias();
    },
    updateParent() {
      this.$emit('updateParent', this.stats);
    },
    zoomIn(val) {
      this.zoom.scaleBy(this.svg.transition().duration(750), val);
    },
    getRequestParams() {
      const params = {};

      params.prediccion = this.filter.prediction;
      params.ubicacionPrecisa = this.filter.ubicacion;
      params.minProb = this.filter.minProb;
      params.maxProb = this.filter.maxProb;
      params.projectId = this.$store.state.projectId;

      if (this.filter.startDate) {
        params.fechaInicio = new Date(this.filter.startDate)
          .toISOString()
          .substring(0, 10)
          .replaceAll('-', '/');
      }
      params.page = 0;
      params.size = 10000;

      return params;
    },
    getNoticias() {
      if (this.loading === true && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/all/`, {
          headers: authHeader(),
          cancelToken: this.cancelTokenSource.token,
          params: this.getRequestParams(),
        })
        .then((response) => {
          this.noticias = response.data.content;
          this.count = response.data.totalElements;
          this.stats.totalNews = this.count;
          this.updateMap();
          this.updateParent();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateMap() {
      if (this.stats.chartLoaded) {
        d3.selectAll('circle').remove();

        // Add circles:
        this.svg
          .selectAll('myCircles')
          .data(this.noticias)
          .join('circle')
          .attr('cx', (d) => this.projection([+d.Longitud, +d.Latitud])[0])
          .attr('cy', (d) => this.projection([+d.Longitud, +d.Latitud])[1])
          .attr('r', (d) => (1200 * d.Probabilidad ** 5) / 85)
          .style('fill', (d) => {
            if (d.Predicción === 'Si') return '#1DE9B6';
            return '#7879F1';
          })
          .style('cursor', 'pointer')
          .attr('stroke-width', 1)
          .attr('fill-opacity', 0.4)
          .on('mousemove', (d) => {
            this.$emit('selectedNews', d.srcElement.__data__);
            d3.select(d.target).attr('fill-opacity', 0.8);
          })
          .on('mouseout', (d) => {
            if (this.clickedNews) this.$emit('selectedNews', this.clickedNews.srcElement.__data__);
            d3.select(d.target).attr('fill-opacity', 0.4);
          })
          .on('click', (d) => {
            if (this.clickedNews) {
              d3.select(this.clickedNews.target).style('stroke', 'transparent');
            }
            this.clickedNews = d;
            d3.select(d.target).style('stroke', '#F4796B').style('stroke-width', 2);
          });

        if (this.lastZoom) {
          this.svg.selectAll('circle').attr('transform', this.lastZoom.transform);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#svg {
  width: 100%;
  height: auto;
}
</style>
<style lang="scss">
.tooltip {
  position: absolute;
  left: 1em;
  bottom: 1em;
  // background-color: red !important;
  border: none;
  border-radius: 9px 1px 9px 1px;
  width: 250px;
}
.circular-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.linear-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.loader-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2000;
}
</style>
