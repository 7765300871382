import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import Dashboard from '../views/Dashboard.vue';
import Noticias from '../views/Noticias.vue';
import SelectProject from '../views/SelectProject.vue';

import UsersManager from '../views/AdminPanel/UsersManager.vue';
import Activity from '../views/AdminPanel/Activity.vue';
import AdminStats from '../views/AdminPanel/Stats.vue';
import ActivityPanel from '../views/Panels/ActivityPanel.vue';

import Validation from '../views/Validation.vue';
import LoginView from '../views/LoginView.vue';

import NotFoundView from '../views/ErrorViews/NotFoundView.vue';
import ErrorGeneric from '../views/ErrorViews/ErrorGeneric.vue';

import store from '@/store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/seleccionar-proyecto',
    name: 'Seleccionar proyecto',
    component: SelectProject,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/tablon-noticias',
    name: 'Tablon de Noticias',
    component: Noticias,
    meta: {
      auth: true,
    },
  },
  {
    path: '/validacion',
    name: 'Validación',
    component: Validation,
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },

  {
    path: '/actividad',
    name: 'Actividad',
    component: Activity,
  },
  {
    path: '/estadisticas',
    name: 'Estadísticas',
    component: AdminStats,
  },
  {
    path: '/gestion-usuarios',
    name: 'Gestion de Usuarios',
    component: UsersManager,
  },
  {
    path: '/actividad-de-usuario/:id',
    name: 'Actividad de Usuario',
    component: ActivityPanel,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorGeneric,
  },
  {
    path: '/error-no-encontrado',
    name: 'No encontrado',
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if ((to.name !== 'Login' && to.name !== 'Validación' && to.name !== 'Inscríbete') && !store.state.auth.status.loggedIn) next({ name: 'Login' });
  else next();
});

export default router;
