<template>
  <div>
    <canvas id="historic-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'inscriptionHistoricChart',

  data() {
    return {
      chartObj: null,
      inscriptionHistoricData: {
        totalNoticias: [],
      },
      inscriptionHistoricChartData: {
        type: 'bar',
        data: {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
            'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              label: 'Noticias',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              backgroundColor: '#F4796B',
              borderColor: '#ff0000e0',
              borderWidth: 0,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
          responsive: true,
          lineTension: 1,
          scales: {
          },
        },
      },
    };
  },

  mounted() {
    const ctx = document.getElementById('historic-chart');
    this.inscriptionHistoricChartData.data.datasets[0].data = this
      .inscriptionHistoricData.totalNoticias;

    // eslint-disable-next-line no-new
    this.charObj = new Chart(ctx, this.inscriptionHistoricChartData);
  },

  methods: {
    updateHistoricInscriptionData(val) {
      this.inscriptionHistoricData.totalNoticias = val.totalNoticias;

      const currentMonth = new Date().getMonth() + 1;

      this.inscriptionHistoricChartData.data.datasets[0].data = this
        .moveArray(this.inscriptionHistoricData.totalNoticias, currentMonth);

      this.inscriptionHistoricChartData.data.labels = this
        .moveArray(this.inscriptionHistoricChartData.data.labels, currentMonth);

      this.charObj.update();
    },
    moveArray(arr, offset) {
      return arr.slice(offset).concat(arr.slice(0, offset));
    },
  },
};
</script>
