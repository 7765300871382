<template>
  <div >
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="items">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div>
      <header-filter
      @updateParent="updateFilter" @downloadClick="downloadClick"
      icon="people" ref="headerFilter">
      </header-filter>
    </div>
    <standard-table ref="table"
    @updateTotal="updateNoticiasTotal"
    >
    </standard-table>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import HeaderFilter from '../components/Filter/HeaderFilter.vue';
import StandardTable from '../components/Tables/Noticias/NoticiasStandardTable.vue';

// eslint-disable-next-line import/no-cycle
import axios from '../services/http-common';
import authHeader from '../services/auth-header';

export default {
  name: 'noticias-data',
  data() {
    return {
      items: [
        {
          text: 'Proyectos',
          disabled: false,
          to: '/seleccionar-proyecto',
        },
        {
          text: '',
          disabled: false,
          to: '/dashboard',
        },
        {
          text: 'Tablón de noticias',
          disabled: true,
          to: '#',
        },
      ],
      filter: {
        fuente: null,
        enlace: null,
        titular: null,
        pais: null,
        prediccion: null,
        ubicacionPrecisa: null,
        fechaInicio: null,
        fechaFin: null,
        minProb: null,
        maxProb: null,
        favorite: null,
        labeledAsYes: null,
        labeledAsNo: null,
        noEval: null,
      },
      loading: false,
      noticiasExport: {},
      count: null,
    };
  },

  methods: {

    updateTable() {
      this.$refs.table.page = 1;
      this.$refs.table.updateFilter(this.filter);
    },
    updateNoticiasTotal(val) {
      this.count = val;
    },
    updateFilter(val) {
      this.filter = val;
    },
    downloadClick(extension) {
      if (extension) {
        this.getItemsAndDownload(extension);
        this.loading = true;
      }
      this.loading = false;
    },
    download(extension) {
      this.noticiasExport = this.noticias.map((item) => {
        const outItem = JSON.parse(JSON.stringify(item));
        return outItem;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.noticiasExport);
      XLSX.utils.book_append_sheet(wb, ws, 'Noticias');

      const date = new Date();
      const dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      const fileName = `Noticias_${dateString}.${extension}`;
      XLSX.writeFile(wb, fileName);
      this.loading = false;
    },

    getRequestParams() {
      const params = {};
      params.projectId = this.$store.state.projectId;
      params.fuente = this.filter.fuente;
      params.pais = this.filter.pais;
      params.prediccion = this.filter.prediccion;
      params.ubicacionPrecisa = this.filter.ubicacionPrecisa;
      params.enlace = this.filter.enlace;
      params.titular = this.filter.titular;
      params.minProb = this.filter.minProb;
      params.maxProb = this.filter.maxProb;
      params.favorite = this.filter.favorite;
      params.labeledAsYes = this.filter.labeledAsYes;
      params.labeledAsNo = this.filter.labeledAsNo;
      params.noEval = this.filter.noEval;

      if (this.filter.fechaInicio) {
        params.fechaInicio = new Date(this.filter.fechaInicio)
          .toISOString()
          .substring(0, 10)
          .replaceAll('-', '/');
      }
      if (this.filter.fechaFin) {
        params.fechaFin = new Date(this.filter.fechaFin)
          .toISOString()
          .substring(0, 10)
          .replaceAll('-', '/');
      }
      params.sort = this.currentSort;
      params.direction = this.currentSortOrder;
      params.size = 100000;
      params.page = 0;

      return params;
    },

    getItemsAndDownload(extension) {
      if (this.loading && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/all/`, {
          headers: authHeader(),
          cancelToken: this.cancelTokenSource.token,
          params: this.getRequestParams(this.page, this.pageSize),
        })
        .then((response) => {
          this.noticias = response.data.content;
          this.loading = false;
          this.download(extension);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled');
          } else {
            console.log(error);
            this.errored = true;
            this.loading = false;
          }
        });
    },
  },
  mounted() {
    if (this.$store.state.project != null) {
      this.items[1].text = this.$store.state.project;
    } else {
      this.$router.push('/seleccionar-proyecto');
    }
  },

  watch: {
    filter: {
      handler() {
        this.updateTable();
      },
      deep: true,
    },
  },

  components: {
    HeaderFilter,
    StandardTable,
  },
};
</script>
<style>
.md-button.md-theme-default.md-raised:not([disabled]).md-primary {
    color: #fff;
    background-color: #FF0000;
}
</style>
