<template>
  <div>
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="back-button">
      <v-btn color="red lighten-1" dark outlined block to="/gestion-usuarios">
        <v-icon left> mdi-arrow-left </v-icon>
        Volver a panel de usuarios
      </v-btn>
    </div>
    <div class="grid-container">
      <div class="left-side">
        <v-card elevation="0" :loading="loading" class="card dashboard_box">
          <!-- <div class="card-thumbnail">
            <md-avatar class="md-avatar-icon md-large card-avatar">A</md-avatar>
          </div> -->
          <v-card-text class="card-label">
            <div>Usuario:</div>
          </v-card-text>
          <v-card-title class="card-title">{{ user.username }}</v-card-title>

          <v-card-text class="card-label">
            <div>Rol:</div>
          </v-card-text>
          <div class="card-role-wrapper">
            <div class="role-title">{{ user.role }}</div>
            <div class="button-edit">
              <v-btn
                color="red lighten-1"
                class="mr-2"
                text
                small
                v-on:click="userEditIsHidden = false"
                v-if="userEditIsHidden"
              >
                <v-icon small class="mr-2">mdi-pencil</v-icon>
                Editar
              </v-btn>
            </div>
            <div class="edit-user-dialog" v-if="!userEditIsHidden">
              <v-select
                :items="RoleOptions"
                v-model="newRole"
                outlined
                color="red lighten-1"
                label="ROLE_SUPER_ADMIN"
                dense
                class="rol-select"
              ></v-select>
              <v-btn
                color="red lighten-1"
                class="mr-2"
                text
                small
                v-on:click="userEditIsHidden = !userEditIsHidden"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="red lighten-1"
                text
                small
                @click="patchUserRole()"
              >
                Guardar
              </v-btn>
            </div>
          </div>

          <v-divider class="mx-4 card-divider"></v-divider>
          <v-card-title class="card-title">Proyectos del usuario</v-card-title>
          <div class="projects-table">
            <div class="dashboard_box">
              <ProjectsTable ref="userProjectTable"/>
            </div>
          </div>
          <v-card-actions class="card-actions">
            <v-btn
              color="red lighten-1"
              text
              v-on:click="projectEditIsHidden = !projectEditIsHidden"
              v-if="projectEditIsHidden"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Añadir proyecto
            </v-btn>
            <div class="add-project-dialog" v-if="!projectEditIsHidden">
              <v-select
                :items="ProjectOptions"
                v-model="projectSelected"
                outlined
                color="red lighten-1"
                label="Proyecto"
                dense
                class="rol-select"
              ></v-select>
              <v-btn
                color="red lighten-1"
                class="mr-2"
                text
                small
                v-on:click="projectEditIsHidden = true"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="red lighten-1"
                text
                small
                v-on:click="projectEditIsHidden = true"
                @click="addProjectToUser()"
              >
                Guardar
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </div>
      <div class="right-side">
        <v-tabs
          v-model="tabActivityLog"
          background-color="transparent"
          color="red lighten-1"
          class="dashboard_box panel-tab"
          grow
        >
          <v-tab v-for="item in tabsActivityLog" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <div class="chart-box dashboard_box" v-show="tabActivityLog === 0">
          <ActivityChart ref="userActivityChart" />
        </div>
        <div class="log-box" v-show="tabActivityLog === 1">
          <log-standard-table :username="user.username"> </log-standard-table>
        </div>
      </div>
    </div>
      </div>
</template>

<script>
import authHeader from '@/services/auth-header';

import ActivityChart from '../../components/Charts/ActivityChart.vue';
import LogStandardTable from '../../components/Tables/Activity/LogActivityTable.vue';
import ProjectsTable from '../../components/Tables/ProjectsTable.vue';

const axios = require('axios');

export default {
  props: {
    cardUserImage: {
      type: String,
      // eslint-disable-next-line global-require
      default: null,
    },
  },
  components: {
    ActivityChart,
    LogStandardTable,
    ProjectsTable,
  },
  data() {
    return {
      userId: null,
      user: [],
      addProject: false,
      tabsActivityLog: ['Actividad', 'Log'],
      tabActivityLog: null,
      breadcrumbs: [
        {
          text: 'Proyectos',
          disabled: false,
          to: '/seleccionar-proyecto',
        },
        {
          text: 'Panel de usuarios',
          disabled: false,
          to: '/gestion-usuarios',
        },
        {
          text: 'UsuarioID',
          disabled: true,
          to: '#',
        },
      ],
      RoleOptions: [
        { value: 'ROLE_USER', text: 'ROLE_USER' },
        { value: 'ROLE_SUPER_ADMIN', text: 'ROLE_SUPER_ADMIN' },
      ],
      ProjectOptions: [],
      projectSelected: null,
      userActivityData: {
        totalActivity: [],
      },
      userEditIsHidden: true,
      projectEditIsHidden: true,
      loading: false,
      newRole: null,
    };
  },

  methods: {
    updateRole(val) {
      this.user.role = val;
    },
    updateUserLocalData() {
      this.breadcrumbs[2].text = this.user.username;
      this.newRole = this.user.role;
      this.$refs.userProjectTable.updateUsername(this.user.username);
    },
    patchUserRole() {
      this.isHidden = true;
      this.user.role = this.newRole;
      this.pathUser();
      this.userEditIsHidden = true;
    },
    addProjectToUser() {
      this.projectEditIsHidden = true;
      this.addProjects();
      this.updateUserLocalData();
    },

    getUser() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}userById/${this.$route.params.id}`,
          {
            headers: authHeader(),
          },
        )
        .then((response) => {
          this.user = response.data;
          this.updateUserLocalData();
          this.getProjects();
          if (!response.data) {
            this.$router.push('/error-no-encontrado');
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProjects() {
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/user-available-projects/${this.user.username}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.ProjectOptions = response.data.map((ele) => ({
            value: ele.projectId,
            text: ele.nombre,
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addProjects() {
      if (this.projectSelected) {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}projectPermissions`,
            {
              username: this.user.username,
              projectId: this.projectSelected,
            },
            { headers: authHeader() },
          )
          .then((response) => {
            console.log(response);
            this.getProjects();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    pathUser() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}update-user-role/${this.$route.params.id}`,
          {
            username: this.user.username,
            role: this.user.role,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.getUser();
        })
        .catch(() => {
          this.$router.push('/error');
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadActivityData() {
      const params = {};
      if (this.$route.params.id) {
        params.userId = this.$route.params.id;
      }
      axios
        .get(`${process.env.VUE_APP_API_URL}stats/userActivity/`, {
          params,
          headers: authHeader(),
        })
        .then((response) => {
          this.userActivityData.totalActivity = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ];

          response.data.forEach((element) => {
            const claveMonth = element[0];
            const valor = element[1];
            if (claveMonth) {
              this.userActivityData.totalActivity[claveMonth - 1] = valor;
            }
          });

          this.$refs.userActivityChart.updateActivityData(
            this.userActivityData,
          );
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getUser();
    this.loadActivityData();
  },
};
</script>
<style lang="scss" scoped>
.back-button {
  max-width: 20em;
  margin-bottom: 1em;
  a {
    text-decoration: none !important;
  }
}
.button-edit {
  margin-right: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-end;
}
.grid-container {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(12, 1fr);
  .left-side {
    grid-column: 1 / span 5;
  }
  .right-side {
    grid-column: 6 / span 7;
  }
  .card {
    padding: 0;
    padding-top: 2em;
  }
}
.dashboard_box {
  border: solid 1px #e5e5e5;
}
.card-thumbnail {
  padding: 1em;
  width: 100%;
  // min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  .card-avatar {
    margin: 0;
  }
}
.card-label.v-card__text {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0.5em;
}
.v-card__title.card-title {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 1em;
}
.projects-table {
  padding-left: 1em;
  padding-right: 1em;
}
.card-divider {
  margin-bottom: 2em;
}
.card-actions.v-card__actions {
  padding: 1em;
  margin-top: 0;
}
.dashboard_box.chart-box {
  margin-bottom: 1em;
}
.panel-tab {
  margin-bottom: 1em;
}
.chart-box {
  padding: 1em;
}
.card-role-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  .role-title {
    font-size: 1.25rem;
  }
}
.add-project-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.edit-user-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.rol-select {
  margin-right: 1em;
}
.edit-user-dialog {
  width: 100%;
  margin-top: 1em;
}
</style>
