import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vue from 'vue';

import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

import VueLayers from 'vuelayers';
import 'vuelayers/dist/vuelayers.css';

import Vuelidate from 'vuelidate';
import vuetify from '@/plugins/vuetify';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import './assets/css/style.scss';

Vue.use(Vuelidate);
Vue.use(VueLayers);

Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueMaterial);

new Vue({
  vuetify,
  validations: {},
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
vuetify.preset.theme.themes.light.primary = '#f40000';
console.log(vuetify.preset.theme.themes.light.primary);
