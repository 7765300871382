<template>
<div class="pt-5 mt-5">
  <md-empty-state
      class="md-accent"
      md-rounded
      md-icon="warning"
      md-label="Error"
      md-description="Los datos no existen en la Base de Datos">
    </md-empty-state>
</div>

</template>

<script>
export default {
  name: 'not-found-view',
};
</script>
