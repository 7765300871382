<template>
  <div>
    <div class="headerLogin">
      <div
        class="
          separator separator-bottom separator-skew
          zindex-100
          headerSeparatorLogin
        "
      >
        <svg
          preserveAspectRatio="none"
          style="display: n"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <md-card class="inputBoxLogin">
      <div class="login-card-header">
        <span
          ><img
            alt="Acciona logo"
            class="m-2"
            src="../assets/acciona-logo.svg"
          />
        </span>
        <div class="text-center text-muted mt-2 mb-4">
          <small>DIANA</small>
        </div>
      </div>
      <md-content>
        <form id="createAdministrator" @submit.prevent="validateUser">
          <div class="md-layout-item md-small-size-100">
            <div class="{ 'form-group--error': $v.name.$error }">
              <v-text-field
                label="Usuario"
                color="red lighten-1"
                placeholder="email"
                outlined
                id="username"
                v-model="$v.input.username.$model"
                type="text"
                :disabled="sending"
                name="username"
              ></v-text-field>
              <div v-if="$v.input.username.$error">
                <span v-if="!$v.input.username.required" class="md-error">
                  Nombre de usuario vacio
                </span>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <v-text-field
              label="Contraseña"
              color="red lighten-1"
              placeholder="********"
              outlined
              id="password"
              v-model="input.password"
              type="password"
              :disabled="sending"
              name="password"
            ></v-text-field>
          </div>
          <div class="login-card-footer mt-5 pt-4 mb-3">
            <div class="btn-login login_user-btn">
            <v-btn color="red lighten-1" dark block type="submit" :disabled="sending">
              <v-icon left>login</v-icon>
              Login
            </v-btn>
          </div>
          </div>

          <md-progress-bar md-mode="indeterminate" v-if="sending" />
        </form>
        <md-dialog-prompt
          :md-active.sync="showPasswordRecovery"
          v-model="passwordRecoveryEmail"
          md-title="Solicitud de recuperación de contraseña"
          md-input-placeholder="Escribe tu email"
          md-confirm-text="Enviar"
          md-cancel-text="Cancelar"
        />

        <div v-if="message" id="errorMessage">
          <p>{{ message }}</p>
        </div>
      </md-content>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'login-view',
  mixins: [validationMixin],
  data() {
    return {
      input: {
        username: '',
        password: '',
      },
      message: '',
      sending: false,
      showPasswordRecovery: false,
      passwordRecoveryEmail: '',
    };
  },
  validations: {
    input: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  watch: {
    passwordRecoveryEmail() {
      this.handleEmailRecovery();
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.input[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
      return '';
    },

    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.handleLogin();
      }
    },
    handleLogin() {
      this.sending = true;
      const user = {};
      user.username = this.input.username;
      user.password = this.input.password;

      this.loading = true;

      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/');
        },
        (error) => {
          this.loading = false;
          if (error.response.status === 403) {
            this.message = 'Usuario o contraseña incorrectos';
          } else {
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
            || error.message
            || error.toString();
          }
          this.sending = false;
        },
      );
    },
    handleEmailRecovery() {
      this.sending = true;
      this.loading = true;

      this.$store
        .dispatch('auth/recoverPassword', this.passwordRecoveryEmail)
        .then(
          () => {
            this.loading = false;
            this.message = 'Se ha enviado un email con las instrucciones para recuperar la contraseña';
            this.sending = false;
          },
          (error) => {
            this.loading = false;
            this.message = (error.response
                && error.response.data
                && error.response.data.message)
              || error.message
              || error.toString();
            this.sending = false;
          },
        );
    },
  },
};
</script>
<style scoped>
.md-app-content {
  padding: 0;
}

.md-card {
  box-shadow: 0 10px 30px -12px rgb(0 0 0 / 22%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}
</style>
<style>
.headerLogin {
  background: linear-gradient(87deg, #f44e3f, #f4796b) !important;
  height: 45vh;
  position: relative;
}

.headerSeparatorLogin {
  height: 150px;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  top: auto;
  bottom: 0;
}

.headerSeparatorLogin svg {
  height: 150px;
  width: 100%;
}

.headerSeparatorLogin svg polygon {
  fill: #f9f9ff;
}

.inputBoxLogin {
  display: block;
  width: 40%;
  margin-left: auto !important;
  margin-right: auto !important;
  border-style: none;
  border-radius: 10px;
  padding: 2rem 3rem;
  border-color: #004a38;
  margin-top: -20vh;
  max-width: 600px;
  min-width: 350px;
}

.login-card-header {
  box-shadow: none;
}

.login-card-header span {
  justify-content: center;
  display: flex;
}

.login-card-header img {
  width: 120px;
}

.login-card-footer button {
  justify-content: center;
  display: flex;
  margin: auto;
  min-width: 120px;
}

.btn {
  background-color: #004a38;
  color: #fff;
}

.btn:hover {
  opacity: 0.88;
  color: #fff;
}

.input-boxshadow {
  box-shadow: 1px 2px rgba(172, 172, 172, 0.603);
}
.login_user-btn {
  max-width: 15em;
  margin-top: 0;
  margin-bottom: 1em;
  display: flex;
  margin: auto;
}
</style>
