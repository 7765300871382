<template>
  <div></div>
</template>

<script>

export default {
  name: 'home-diana',
  data: () => ({
    project: null,
    userId: null,
    userRole: null,
    userName: null,
    user: [],
  }),
  components: {

  },
  mounted() {
    this.redirectView();
  },
  methods: {
    redirectView() {
      if (this.$store.state.project) {
        this.$router.push('/dashboard');
      } else {
        this.$router.push('/seleccionar-proyecto');
      }
    },
  },
  computed: {

  },
  watch: {

  },
};
</script>
