<template>
  <div class="dashboard-main">
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="items">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="dashboard-tabs dashboard_box">
      <v-tabs
        v-model="tabMapa"
        background-color="transparent"
        color="red lighten-1"
        grow
      >
        <v-tab v-for="item in tabsMapa" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
    </div>

    <div class="dashboard-grid">
      <div class="dashboard_box map-box twelve-col three-row">
        <div :class="mapClass">
          <NewsMap
            ref="newsMap"
            @updateParent="updateStats"
            @updateChartLoaded="updateFilter()"
            @selectedNews="updateTooltip"
          >
          </NewsMap>
        </div>
        <div class="map-zoom">
          <v-btn class="mx-auto" fab dark x-small color="grey"
          @click="$refs.newsMap.zoomIn(1.4)">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <v-btn class="mx-auto" fab dark x-small color="grey"
          @click="$refs.newsMap.zoomIn(0.6)">
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
        </div>
        <div class="map-search">
          <v-text-field
            dense
            label="Buscar por ubicación"
            placeholder="Por ejemplo Spain"
            color="red lighten-1"
            class="map-search-input"
            outlined
            v-model="ubicacionFilter"
          ></v-text-field>
          <div class="map-chips-wrapper">
            <v-chip
              v-if="chip1 && ubicacionFilter != null && ubicacionFilter != ''"
              class="ma-2"
              color="red lighten-1"
              close
              dark
              @click:close="ubicacionFilter = null"
            >
              {{ this.ubicacionFilter }}
            </v-chip>
            <v-chip
              v-if="predictionSi"
              class="ma-2"
              color="teal accent-3"
              close
              dark
              @click:close="predictionSi = false"
            >
              Predicción: Sí
            </v-chip>
            <v-chip
              v-if="predictionNo"
              class="ma-2"
              color="deep-purple lighten-2"
              close
              dark
              @click:close="predictionNo = false"
            >
              Predicción: No
            </v-chip>
            <v-chip
              v-if="parseFloat(range[0]) != 0.5 || parseFloat(range[1]) != 1.0"
              class="ma-2"
              color="red lighten-1"
              close
              dark
              @click:close="range = [0.5, 1]"
            >
              Probabilidad: {{ this.range[0] }}~{{ this.range[1] }}
            </v-chip>
          </div>
        </div>
        <div class="map-tooltip" v-if="selectedNews">
          <div class="tooltip-vue">
            <div class="tooltip-first-line">
              <div class="tooltip-date">{{ seletedNewsDate }}</div>
              <div class="tooltip-guess">
                <span class="tooltip-probabilidad">
                  {{ (selectedNews.Probabilidad * 100).toFixed(2) }} %</span
                >
                <div class="tooltip-prediccion">
                  <v-chip
                    v-if="selectedNews.Predicción == 'Si'"
                    drag
                    dark
                    color="teal accent-3"
                    small
                    >SI
                  </v-chip>
                  <v-chip
                    v-if="selectedNews.Predicción == 'No'"
                    drag
                    dark
                    color="deep-purple lighten-2"
                    small
                  >
                    NO
                  </v-chip>
                </div>
              </div>
            </div>
            <div class="tooltip-second-line">
              <country-flag :country="selectedNewsCountryISO" size="normal" />
              <span class="tooltip-location">{{
                selectedNews.Ubicación_Precisa
              }}</span>
              <span class="tooltip-title">{{
                selectedNews.Titular_ENG
              }}</span>
            </div>
            <div class="tooltip-last-line">
              <div class="tooltip-fuente">{{ selectedNews.Fuente }}</div>
              <a :href="selectedNews.Enlace" target="_blank" class="tooltip-link">
                <v-icon color="red lighten-1">mdi-vector-link</v-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="map-range">
          <v-range-slider
            v-model="range"
            :max="max"
            :min="min"
            hide-details
            label="Probabilidad:"
            color="red lighten-1"
            track-color="red lighten-4"
            class="align-center"
            step="0.05"
            thumb-color="red lighten-1"
            thumb-label="always"
          >
          </v-range-slider>
        </div>
        <div class="map-switches">
          <div class="switches-label">Filtrar por predicción:</div>
          <div class="switches-wrapper">
            <v-switch
              v-model="predictionSi"
              label="Si"
              class="switch-si"
              color="teal accent-3"
              hide-details
            ></v-switch>
            <v-switch
              v-model="predictionNo"
              label="No"
              class="switch-no"
              color="deep-purple lighten-2"
              hide-details
            ></v-switch>
          </div>
        </div>
      </div>
      <div class="dashboard_box small-box box-1 two-col one-row noticias-box">
        <v-icon color="red lighten-1" x-large>mdi-newspaper</v-icon>
        <span class="noticias-total">{{ stats.totalNews }}</span>
        <span class="noticias-text">{{ stats.totalNewsText }}</span>
      </div>
      <div class="dashboard_box small-box box-2 two-col one-row">
        <LabeledChart ref="newsChart" @updateParent="updateChartStats" />
      </div>
      <div class="dashboard_box small-box box-3 two-col one-row noticias-box">
        <v-icon color="red lighten-1" x-large>percent</v-icon>
        <span class="noticias-total">{{ stats.percentLabeled }}</span>
        <span class="noticias-text">Porcentaje de evaluación</span>
      </div>
    </div>
    <div class="dashboard-table">
      <div class="table-tabs dashboard_box">
        <v-tabs
          v-model="tabNoticias"
          background-color="transparent"
          color="red lighten-1"
          grow
        >
          <v-tab v-for="item in tabsNoticias" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="table-container" v-show="tabNoticias === 0">
        <small-table ref="newsTable"></small-table>
      </div>
      <div class="table-container" v-show="tabNoticias === 1">
        <favorites-table></favorites-table>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import countries from '@/utils/countries.json';
import NewsMap from '../components/Map/Map.vue';
import SmallTable from '../components/Tables/Noticias/NoticiasSmallTable.vue';
import FavoritesTable from '../components/Tables/Noticias/NoticiasFavoriteSmallTable.vue';
import LabeledChart from '../components/Charts/LabeledFilteredChart.vue';

export default {
  name: 'EmptyStateRounded',
  data: () => ({
    project: null,
    mapClass: 'map-proj-unselected',
    items: [
      {
        text: 'Proyectos',
        disabled: false,
        to: '/seleccionar-proyecto',
      },
      {
        text: '',
        disabled: false,
        to: '/dashboard',
      },
      {
        text: 'Dashboard',
        disabled: true,
        to: '#',
      },
    ],

    info: null,
    loading: true,
    errored: false,
    assemblyData: {
      assemblyType: ' - ',
      assemblyName: ' - ',
      assemblyDescription: ' - ',
      assemblyGroup: ' - ',
      link1: ' - ',
      link2: ' - ',
    },
    userId: null,
    userRole: null,
    userName: null,
    user: [],
    filterAvailable: false,
    statsAvailable: false,
    tableAvailable: false,
    projectSelectionClass: 'sel-proj-unselected',
    ex11: [
      'red',
      'indigo',
      'orange',
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'error',
      'red darken-3',
      'indigo darken-3',
      'orange darken-3',
    ],
    predictionSi: true,
    predictionNo: false,
    ubicacionFilter: null,
    tabNoticias: 0,
    tabMapa: 0,
    tabsNoticias: ['Últimas noticias', 'Favoritas'],
    tabsMapa: ['Últimos 30 días', 'Últimos 12 Meses', 'Histórico'],
    filter: {
      prediction: 'Si',
      ubicacion: null,
      startDate: new Date().setDate(new Date().getDate() - 30),
      minProb: 0.5,
      maxProb: 1,
    },
    countriesList: countries,
    stats: {
      totalNews: 0,
      totalNewsText: 'Noticias en el mes actual',
      percentLabeled: 0.0,
    },
    chip1: true,
    min: 0,
    max: 1,
    range: [0.5, 1],
    selectedNews: null,
  }),
  components: {
    NewsMap,
    SmallTable,
    FavoritesTable,
    LabeledChart,
    CountryFlag,
  },
  mounted() {
    if (this.$store.state.project != null) {
      this.items[1].text = this.$store.state.project;
    } else {
      this.$router.push('/seleccionar-proyecto');
    }
  },
  methods: {
    updateStats(val) {
      this.stats.totalNews = val.totalNews;
    },
    updateChartStats(val) {
      this.stats.percentLabeled = val.percentLabeled;
    },
    updateTooltip(val) {
      this.selectedNews = val;
    },
    setPredictionFilter() {
      if (this.predictionSi && this.predictionNo) {
        this.filter.prediction = null;
      } else if (this.predictionSi) {
        this.filter.prediction = 'Si';
      } else if (this.predictionNo) {
        this.filter.prediction = 'No';
      } else {
        this.filter.prediction = 'XX';
      }
    },
    updateFilter() {
      this.$refs.newsTable.page = 1;
      this.$refs.newsTable.updateFilter(this.filter);
      this.$refs.newsMap.updateFilter(this.filter);
      this.$refs.newsChart.updateFilter(this.filter);
    },
    setStartDateFilter() {
      const date = new Date();
      if (this.tabMapa === 0) {
        this.filter.startDate = date.setDate(date.getDate() - 30);
        this.stats.totalNewsText = 'Noticias en el mes actual';
      } else if (this.tabMapa === 1) {
        this.filter.startDate = date.setDate(date.getDate() - 365);
        this.stats.totalNewsText = 'Noticias en el último año';
      } else if (this.tabMapa === 2) {
        this.filter.startDate = null;
        this.stats.totalNewsText = 'Noticias totales';
      }
    },

  },
  computed: {
    seletedNewsDate() {
      const date = new Date(this.selectedNews.Date);
      return `${date.toLocaleString(undefined, {
        year: '2-digit',
        month: 'short',
        day: '2-digit',
      })} - ${date.toLocaleString(undefined, {
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
      })}`;
    },
    selectedNewsCountryISO() {
      const foundCountry = this.countriesList.find(
        (country) => country.Codigo === this.selectedNews.ISO2,
      );
      if (foundCountry) {
        return foundCountry.ISO2;
      }
      return 'XX';
    },
  },
  watch: {
    predictionSi() {
      this.setPredictionFilter();
      this.updateFilter();
    },
    predictionNo() {
      this.setPredictionFilter();
      this.updateFilter();
    },
    ubicacionFilter(val) {
      this.filter.ubicacion = val;
      this.updateFilter();
    },
    range(val) {
      // eslint-disable-next-line prefer-destructuring
      this.filter.minProb = val[0];
      // eslint-disable-next-line prefer-destructuring
      this.filter.maxProb = val[1];
      this.updateFilter();
    },
    tabMapa() {
      this.setStartDateFilter();
      this.updateFilter();
    },
  },
};
</script>

<style lang="scss" scoped>
$corporateColor: #f40000;
$corporateLight: #fee6e6;
$corporateMedium: #f4796b;
div {
  text-align: center;
}

.sel-proj-selected {
  display: none;
}
.dashboard-nav {
  margin-bottom: 1em;
}
.dashboard-tabs {
  margin-bottom: 1em;
}
.dashboard-grid {
  min-height: 50vh;
  max-height: 80vh;
  height: 100vh;
  border-color: orange;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 14px;
  row-gap: 14px;
  margin-bottom: 1em;
  .two-col {
    grid-column: span 2;
  }
  .twelve-col {
    grid-column: 1 / span 10;
  }
  // .one-row {
  //   grid-row: span 1;
  // }
  .three-row {
    grid-row: 1 / span 3;
  }
  .box-1 {
    grid-column: 11 / span 2;
    grid-row: 1 / span 1;
  }
  .box-2 {
    grid-column: 11 / span 2;
    grid-row: 2 / span 1;
  }
  .box-3 {
    grid-column: 11 / span 2;
    grid-row: 3 / span 1;
  }
}
.map-box {
  position: relative;
  height: 100%;
  overflow: hidden;

  .map-proj-unselected {
    position: relative;
    height: 100%;
    div {
      height: 100%;
    }
  }
  #map-container {
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      height: auto;
    }
  }
}
.map-zoom {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
  .v-btn:first-of-type {
    margin-bottom: 0.5em;
  }
}
.map-switches {
  position: absolute;
  top: 1em;
  right: 1em;
  .switches-label {
    margin-bottom: 0.5em;
    text-align: right;
    font-size: 14px;
    line-height: 1;
    color: $corporateMedium;
  }
  .switches-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
<style lang="scss">
.map-range {
  position: absolute;
  bottom: 1em;
  right: 1em;
  width: 33%;
  label.v-label.theme--light {
    font-size: 14px;
    margin-bottom: 0;
    color: #f4796b;
  }
}
.breadcrumbs-container {
  .v-breadcrumbs {
    padding-top: 0;
    padding-bottom: 1em;
    a.v-breadcrumbs__item--disabled {
      color: rgba(0, 0, 0, 0.54) !important;
    }
    a {
      color: #f4796b !important;
    }
    a:hover {
      color: ligthen(#f4796b, 40%);
    }
  }
}
.map-search {
  position: absolute;
  left: 1em;
  top: 1em;
  max-width: 33%;
  min-width: 33%;
  .map-chips-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -0.5em;
    .v-chip {
      margin: 0.5em;
      margin-bottom: 0;
    }
  }
}
.v-text-field__details {
  display: none;
}
.dashboard_box {
  border: solid 1px #e5e5e5;
  border-radius: 4px;
  background-color: #fff;
}
.map-search-input .v-input__slot {
  border: none !important;
  border-color: green;
  outline: none;
}
.table-tabs {
  margin-bottom: 1em;
}
.md-switch .md-switch-label {
  min-width: 2.5em !important;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 0;
  min-width: 1.5em;
}
.v-input--selection-controls.v-input {
  margin-top: 0;
}
.noticias-box {
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .noticias-total {
    font-size: 1.71em;
    color: #e57373;
  }
  .noticias-text {
    line-height: 1.1;
    max-width: 8em;
  }
}
.map-tooltip {
  position: absolute;
  left: 1em;
  bottom: 1em;
  background-color: rgba(254, 230, 230, 0.5);
  color: #f4796b;
  border: none;
  border-radius: 9px 1px 9px 1px;
  padding: 1em;
  max-width: 300px;
  min-width: 250px;
  font-size: 14px;
  .tooltip-vue {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    span.flag {
      // font-size: 34px;
      margin-bottom: 0.2em;
      border-radius: 4px;
    }
    .tooltip-first-line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;
      width: 100%;
    }
    .tooltip-second-line {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1em;
      text-align: left;
      line-height: 1.2;
      .tooltip-location {
        margin-right: 2.5em;
        font-size: 16px;
      }
      .tooltip-title {
        margin-top: 1.25em;
        font-size: 12px;
      }
    }
    .tooltip-last-line {
      display: flex;
      justify-content: space-between;
      .tooltip-link {
        margin-right: 0.5em;
      }
    }
    .tooltip-guess {
      display: flex;
      flex-wrap: nowrap;
      .tooltip-probabilidad {
        margin-right: 0.3em;
      }
    }
  }
}
// * {
//   border: solid 1px blue;
// }
</style>
<style scoped>
.v-text-field--outlined >>> fieldset {
  background-color: #fee6e6;
  border-color: #ef5350;
  color: #ef5350;
  opacity: 70%;
}
.v-text-field--outlined >>> input {
  color: #ef5350;
}
.tooltip-fuente {
  max-width: 90%;
  word-break: break-word;
  text-align: left;
  line-height: 1.2;
}
</style>
