<template>
  <div>
    <div class="filter-prediccion mb-5">
        <div class="prediccion-container checkbox-container">
          <v-label>Predicción:</v-label>
          <div class="checkboxes-wrapper">
            <v-checkbox
              v-model="predictionSi"
              label="Si"
              color="teal accent-3"
              hide-details
              small
            ></v-checkbox>
            <v-checkbox
              v-model="predictionNo"
              label="No"
              color="deep-purple lighten-2"
              hide-details
              small
            ></v-checkbox>
          </div>
        </div>
        <div class="evaluadas-container checkbox-container">
          <v-label>Evaluadas:</v-label>
          <div class="checkboxes-wrapper">
            <v-checkbox
              v-model="filter.labeledAsYes"
              label="Si"
              color="teal accent-3"
              hide-details
              small
            ></v-checkbox>
            <v-checkbox
              v-model="filter.labeledAsNo"
              label="No"
              color="deep-purple lighten-2"
              hide-details
              small
            ></v-checkbox>
            <v-checkbox
              v-model="filter.noEval"
              color="grey"
              label="Sin evaluar"
              hide-details
              small
            ></v-checkbox>
          </div>
        </div>
        <div class="favoritas-container checkbox-container">
          <v-label>Favoritas:</v-label>
          <div class="checkboxes-wrapper">
            <v-checkbox
              v-model="favoriteBoolean"
              label=""
              color="red lighten-1"
              hide-details
              small
            ></v-checkbox>
          </div>
        </div>
        <div class="evaluadas-container checkbox-container">
          <v-label>Fecha:</v-label>
          <div class="checkboxes-wrapper">
            <v-radio-group v-model="filterStandardDate" row hide-details>
              <v-radio
              label="Última semana"
              color="red lighten-1"
              small
              :key="1"
              :value="1"
               ></v-radio>
               <v-radio
              label="Último mes"
              color="red lighten-2"
              small
              :key="2"
              :value="2"
               ></v-radio>
               <v-radio
              label="Últimos 12 meses"
              color="red lighten-3"
              small
              :key="3"
              :value="3"
               ></v-radio>
               <v-radio
              label="Personalizado"
              color="grey"
              small
              :key="4"
              :value="4"
               ></v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
    <div class="card-filter-container">
      <v-text-field
        v-model="filter.enlace"
        outlined
        label="Enlace"
        color="red lighten-2"
        prepend-inner-icon="mdi-vector-link"
      ></v-text-field>
      <v-text-field
        v-model="filter.titular"
        outlined
        label="Titular"
        color="red lighten-2"
        prepend-inner-icon="mdi-format-title"
      ></v-text-field>
      <v-text-field
        v-model="filter.pais"
        outlined
        label="País"
        color="red lighten-2"
        prepend-inner-icon="mdi-flag"
      ></v-text-field>
      <v-text-field
        v-model="filter.ubicacionPrecisa"
        outlined
        label="Ubicación precisa"
        color="red lighten-2"
        prepend-inner-icon="mdi-map-marker"
      ></v-text-field>

      <!-- Third row -->
      <div class="fecha-inicio fecha-wrapper" v-if="filterStandardDate === 4">
        <v-text-field
          color="red lighten-2"
          v-model="dateStart"
          label="Fecha inicio"
          prepend-inner-icon="mdi-calendar"
          outlined
          @click="clickDateStart"
        ></v-text-field>
        <v-date-picker
          v-if="filterStartDate"
          v-model="filter.fechaInicio"
          color="red lighten-2"
        ></v-date-picker>
      </div>
      <div class="fecha-fin fecha-wrapper" v-if="filterStandardDate === 4">
        <v-text-field
          color="red lighten-2"
          v-model="dateEnd"
          label="Fecha fin"
          prepend-inner-icon="mdi-calendar"
          outlined
          @click="clickDateEnd"
        ></v-text-field>
        <v-date-picker
          v-if="filterEndDate"
          v-model="filter.fechaFin"
          color="red lighten-2"
        ></v-date-picker>
      </div>
      <div class="range">
        <v-range-slider
          v-model="range"
          :max="max"
          :min="min"
          hide-details
          label="Probabilidad:"
          color="red lighten-1"
          track-color="red lighten-4"
          class="align-center"
          step="0.05"
          thumb-color="red lighten-1"
          thumb-label="always"
        >
        </v-range-slider>
      </div>
      <div class="download">
        <v-menu
          open-on-hover
          bottom
          offset-y
          v-if="
            this.authService.getDecodedJwtToken(this.$store.state.auth.user)
              .rol === 'ROLE_SUPER_ADMIN'
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red lighten-1"
              large
              dark
              v-bind="attrs"
              v-on="on"
              block
            >
              <v-icon>mdi-download</v-icon>
              Descargar
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="downloadClick('.xlsx')">
              <v-list-item-title>Excel (.xlsx)</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadClick('.csv')">
              <v-list-item-title>CSV (.csv)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="filter-chips-container">
      <v-chip
        class="ma-2 borrar-filtros"
        color="rgba(0,0,0,0.56)"
        close
        outlined
        @click="resetFilter()"
        @click:close="resetFilter()"
      >
        Resetear filtros
      </v-chip>
      <v-chip
        v-if="filter.ubicacionPrecisa != null && filter.ubicacionPrecisa != ''"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="filter.ubicacionPrecisa = null"
      >
        Ubicación: {{ this.filter.ubicacionPrecisa }}
      </v-chip>
      <v-chip
        v-if="predictionSi"
        class="ma-2"
        color="teal accent-3"
        close
        outlined
        dark
        @click:close="predictionSi = false"
      >
        Predicción: Sí
      </v-chip>
      <v-chip
        v-if="predictionNo"
        class="ma-2"
        color="deep-purple lighten-2"
        close
        outlined
        dark
        @click:close="predictionNo = false"
      >
        Predicción: No
      </v-chip>
      <v-chip
        v-if="favoriteBoolean"
        class="ma-2"
        color="red lighten-2"
        close
        dark
        @click:close="favoriteBoolean = false"
      >
        Favoritos: Sí
      </v-chip>
      <v-chip
        v-if="filter.labeledAsYes"
        class="ma-2"
        color="teal accent-3"
        close
        dark
        @click:close="filter.labeledAsYes = false"
      >
        Evaluación: Sí
      </v-chip>
      <v-chip
        v-if="filter.labeledAsNo"
        class="ma-2"
        color="deep-purple lighten-2"
        close
        dark
        @click:close="filter.labeledAsNo = false"
      >
        Evaluación: No
      </v-chip>
      <v-chip
        v-if="filter.noEval"
        class="ma-2"
        color="grey"
        close
        dark
        @click:close="filter.noEval = false"
      >
        Evaluación: Sin evaluar
      </v-chip>
      <v-chip
        v-if="filter.enlace != null && filter.enlace != ''"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="filter.enlace = null"
      >
        Enlace: {{ this.filter.enlace }}
      </v-chip>
      <v-chip
        v-if="filter.titular != null && filter.titular != ''"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="filter.titular = null"
      >
        Titular: {{ this.filter.titular }}
      </v-chip>
      <v-chip
        v-if="filter.pais != null && filter.pais != ''"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="filter.pais = null"
      >
        País: {{ this.filter.pais }}
      </v-chip>
      <v-chip
        v-if="dateStart != null || dateEnd != null"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="resetDateFilter()"
      >
        Fechas: {{ this.dateStart }}~{{ this.dateEnd }}
      </v-chip>
      <v-chip
        v-if="parseFloat(range[0]) != 0.5 || parseFloat(range[1]) != 1.0"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="range = [0.5, 1]"
      >
        Probabilidad: {{ this.range[0] }}~{{ this.range[1] }}
      </v-chip>
    </div>
     <v-divider></v-divider>
  </div>
</template>

<script>
import authService from '../../services/auth.service';

export default {
  name: 'header-filter',
  props: {
    icon: {
      type: String,
      default: 'people',
    },
  },
  data: () => ({
    dates: [],
    authService,
    unCollapsed: false,
    total: null,
    filterStartDate: false,
    filterEndDate: false,
    predictionSi: true,
    predictionNo: true,
    favoriteBoolean: false,
    labeledBoolean: false,
    filterStandardDate: 1,
    filter: {
      fuente: null,
      enlace: null,
      titular: null,
      pais: null,
      prediccion: null,
      ubicacionPrecisa: null,
      fechaInicio: new Date().setDate(new Date().getDate() - 7),
      fechaFin: null,
      minProb: 0.5,
      maxProb: 1,
      favorite: -1,
      labeledAsYes: true,
      labeledAsNo: false,
      noEval: true,
    },

    min: 0,
    max: 1,
    range: [0.5, 1],
  }),
  components: {},
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    dateStart() {
      if (this.filter.fechaInicio) {
        return new Date(this.filter.fechaInicio).toISOString().split('T')[0];
      }
      return null;
    },
    dateEnd() {
      if (this.filter.fechaFin) {
        return new Date(this.filter.fechaFin).toISOString().split('T')[0];
      }
      return null;
    },
  },
  methods: {
    clickDateStart() {
      this.filterStartDate = !this.filterStartDate;
    },
    clickDateEnd() {
      this.filterEndDate = !this.filterEndDate;
    },
    updateTotalBox(val) {
      this.total = val;
    },
    updateParent() {
      this.$emit('updateParent', this.filter);
    },
    downloadClick(extension) {
      this.$emit('downloadClick', extension);
    },
    applyFilter() {
      this.$emit('applyFilter');
    },
    resetFilter() {
      this.filter.ubicacionPrecisa = null;
      this.predictionSi = true;
      this.predictionNo = true;
      this.favoriteBoolean = false;
      this.range = [0.5, 1];
      this.filter.enlace = null;
      this.filter.pais = null;
      this.filter.labeledAsYes = true;
      this.filter.labeledAsNo = false;
      this.filter.noEval = true;
      this.resetDateFilter();
    },
    resetDateFilter() {
      this.filter.fechaInicio = null;
      this.filter.fechaFin = null;
      this.filterStandardDate = 4;
    },
    setPredictionFilter() {
      if (this.predictionSi && this.predictionNo) {
        this.filter.prediccion = null;
      } else if (this.predictionSi) {
        this.filter.prediccion = 'Si';
      } else if (this.predictionNo) {
        this.filter.prediccion = 'No';
      } else {
        this.filter.prediccion = 'XX';
      }
    },
    setFavoriteFilter() {
      if (this.favoriteBoolean) {
        this.filter.favorite = 0;
      } else {
        this.filter.favorite = -1;
      }
    },
    setLabeledFilter() {
      if (this.labeledBoolean) {
        this.filter.labeled = 0;
      } else {
        this.filter.labeled = -1;
      }
    },
  },
  created() {
    this.updateParent();
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.unCollapsed = !isJustShown;
    });
  },
  watch: {
    predictionSi() {
      this.setPredictionFilter();
    },
    predictionNo() {
      this.setPredictionFilter();
    },
    favoriteBoolean() {
      this.setFavoriteFilter();
    },
    labeledBoolean() {
      this.setLabeledFilter();
    },
    filterStandardDate(val) {
      if (val === 1) {
        this.filter.fechaInicio = new Date().setDate(new Date().getDate() - 7);
        this.filter.fechaFin = null;
      } else if (val === 2) {
        this.filter.fechaInicio = new Date().setDate(new Date().getDate() - 30);
        this.filter.fechaFin = null;
      } else if (val === 3) {
        this.filter.fechaInicio = new Date().setDate(new Date().getDate() - 365);
        this.filter.fechaFin = null;
      } else if (val === 4) {
        this.filter.fechaInicio = null;
        this.filter.fechaFin = null;
      }
    },
    range(val) {
      // eslint-disable-next-line prefer-destructuring
      this.filter.minProb = val[0];
      // eslint-disable-next-line prefer-destructuring
      this.filter.maxProb = val[1];
    },
  },
};
</script>
<style scoped>
.card-filter-button {
  padding: 0 10px;
  display: flex;
  background-color: #fff;
  border: none;
}
.card-filter-button:hover {
  background-color: #fff;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}
.card-filter-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  column-gap: 1em;
  row-gap: 1em;
  margin: 0;
  margin-bottom: 1em;
}
.filter-chips-container {
  margin-bottom: 1em;
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.checkboxes-wrapper {
  display: flex;
}
</style>
<style lang="scss">
.range {
  padding-top: 1em;
  padding-right: 1em;
}
.v-picker--date{
  position:absolute;
  z-index: 9999;
 box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.filter-prediccion {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  label {
    // line-height: 34px;
    margin: 0;
    margin-right: 1em;
  }
}
.fecha-wrapper {
  .v-input {
    margin-bottom: 1em;
  }
}
.checkbox-container {
  margin-right: 2em;
  .v-label {
    margin-right: 0em;
    margin-bottom: 0.5em;
  }
  .v-input__control {
    margin-right: 0.2em;
  }
}
.download {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
.v-btn:not(.v-btn--round).v-size--default {
  width: 200px;
}
// * {
//   border: solid 1px blue;
// }
.v-chip.borrar-filtros {
  border: none !important;
}
</style>
