<template>
        <div class="md-layout m-2 mt-4 mb-5">
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <md-icon>badge</md-icon>
              <label>Contraseña</label>
              <md-input v-model="user.password" type="password"></md-input>
            </md-field>
          </div>
        </div>
</template>
<script>

export default {
  name: 'user-data-register',
  data: () => ({
    user: {
      name: null,
      password: null,
    },
  }),
  created() {
    this.updateUserData();
  },
  methods: {
    updateUserData() {
      this.$emit('updateUser', this.user);
    },
  },
};
</script>
