<template>
  <div>
    <canvas id="labeled-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'Labeled-Chart',

  data() {
    return {
      chartObj: null,
      labeledData: {
        totalEtiquetadas: 0,
        totalNoEtiquetadas: 0,
      },
      labeledChartData: {
        type: 'doughnut',
        data: {
          labels: ['Evaluadas', 'No Evaluadas'],
          datasets: [
            {
              label: 'Total de noticias',
              data: [0, 0],
              backgroundColor: ['#F4796B', '#e5e5e5'],
              borderColor: '#fff',
              borderWidth: 0,
            },
          ],
        },
        options: {
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          plugins: {

            legend: {
              position: 'bottom',
            },
          },
          responsive: true,
          autoPadding: false,
          lineTension: 1,
          scales: {},
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById('labeled-chart');
    this.labeledChartData.data.datasets[0].data = [
      this.labeledData.totalEtiquetadas,
      this.labeledData.totalNoEtiquetadas,
    ];
    // eslint-disable-next-line no-new
    this.charObj = new Chart(ctx, this.labeledChartData);
  },

  methods: {
    updateLabeledData(val) {
      this.labeledData.totalEtiquetadas = val.totalEtiquetadas;
      this.labeledData.totalNoEtiquetadas = val.totalNoEtiquetadas;

      this.labeledChartData.data.datasets[0].data = [
        this.labeledData.totalEtiquetadas,
        this.labeledData.totalNoEtiquetadas,
      ];
      this.charObj.update();
    },
  },
};
</script>
