<template>
<div class="pt-5">
  <md-empty-state
      class="md-accent"
      md-rounded
      md-icon="priority_high"
      md-label="Ooops"
      md-description="Ha habido un problema en el registro.
       Por favor, contacta con el administrador.">
    </md-empty-state>
</div>
</template>

<script>
export default {
  name: 'not-found-view',
};
</script>
