import { render, staticRenderFns } from "./NoticiasStandardTable.vue?vue&type=template&id=6441a314&scoped=true&"
import script from "./NoticiasStandardTable.vue?vue&type=script&lang=js&"
export * from "./NoticiasStandardTable.vue?vue&type=script&lang=js&"
import style0 from "./NoticiasStandardTable.vue?vue&type=style&index=0&lang=css&"
import style1 from "./NoticiasStandardTable.vue?vue&type=style&index=1&id=6441a314&scoped=true&lang=css&"
import style2 from "./NoticiasStandardTable.vue?vue&type=style&index=2&id=6441a314&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6441a314",
  null
  
)

export default component.exports