<template>
  <div>
    <canvas id="labeled-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'labeled-filtered-chart',

  data() {
    return {
      chartObj: null,
      totalNoticias: 0,
      labeledData: {
        totalEtiquetadas: 0,
        totalNoEtiquetadas: 0,
      },
      stats: {
        percentLabeled: 0.0,
      },
      loading: false,
      cancelTokenSource: null,
      filter: {
        prediction: null,
        ubicacion: null,
        startDate: null,
        minProb: null,
        maxProb: null,
      },
      labeledChartData: {
        type: 'doughnut',
        data: {
          labels: ['Evaluadas', 'No Evaluadas'],
          datasets: [
            {
              label: 'Total de noticias',
              data: [0, 0],
              backgroundColor: ['#EF5350', '#e5e5e5'],
              borderColor: '#fff',
              borderWidth: 0,
            },
          ],
        },
        options: {
          layout: {
            padding: {
              left: 14,
              right: 14,
              top: 14,
              bottom: 14,
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
          responsive: true,
          autoPadding: false,
          lineTension: 1,
          scales: {},
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById('labeled-chart');
    this.labeledChartData.data.datasets[0].data = [
      this.labeledData.totalEtiquetadas,
      this.labeledData.totalNoEtiquetadas,
    ];
    // eslint-disable-next-line no-new
    this.charObj = new Chart(ctx, this.labeledChartData);
  },

  methods: {
    updateLabeledData(val) {
      this.labeledData.totalEtiquetadas = val.totalEtiquetadas;
      this.labeledData.totalNoEtiquetadas = val.totalNoEtiquetadas;

      this.labeledChartData.data.datasets[0].data = [
        this.labeledData.totalEtiquetadas,
        this.labeledData.totalNoEtiquetadas,
      ];
      this.charObj.update();
    },

    getRequestParams() {
      const params = {};

      params.projectId = this.$store.state.projectId;
      params.prediccion = this.filter.prediction;
      params.ubicacionPrecisa = this.filter.ubicacion;
      params.minProb = this.filter.minProb;
      params.maxProb = this.filter.maxProb;

      if (this.filter.startDate) {
        params.fechaInicio = new Date(this.filter.startDate)
          .toISOString()
          .substring(0, 10)
          .replaceAll('-', '/');
      }

      return params;
    },

    updateFilter(val) {
      this.filter = val;
      this.loadLabeledChartData();
    },
    updateParent() {
      this.$emit('updateParent', this.stats);
    },

    loadLabeledChartData() {
      if (this.loading && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/stats/filteredcount`, {
          headers: authHeader(),
          params: this.getRequestParams(),
        })
        .then((response) => {
          this.totalNoticias = 0;
          this.labeledData.totalEtiquetadas = 0;
          this.labeledData.totalNoEtiquetadas = 0;
          this.stats.percentLabeled = 0.00;
          if (response.data[0]) {
            this.totalNoticias = response.data[0].total.num;
            if (response.data[0].labeled) {
              this.labeledData.totalEtiquetadas = response.data[0].labeled.num;
            }
            this.labeledData.totalNoEtiquetadas = this.totalNoticias
            - this.labeledData.totalEtiquetadas;
            this.stats.percentLabeled = (100 * this.labeledData.totalEtiquetadas)
            / this.totalNoticias;
            this.stats.percentLabeled = this.stats.percentLabeled.toFixed(2);
          }
          this.updateLabeledData(this.labeledData);
          this.updateParent();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
