<template>
<div>
<md-list-item v-for="item in projects" :key="item.value" @click="updateProject(item)">
      <md-icon>{{ item.icon }}</md-icon>
      <span class="md-list-item-text">{{ item.text }}</span>
    </md-list-item>
</div>
</template>
<script>
import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'project-selection-nav',
  props: {
  },
  data: () => ({
    projects: [],
  }),
  created() {
    this.getProjects();
    if (this.value) {
      this.internalValue = this.value;
      this.setSelectedLabel();
    }
  },

  methods: {
    updateProject(val) {
      this.$store.commit('setProject', val);
      this.$router.push('/');
    },

    getRequestParams() {
      const params = {};
      return params;
    },

    updateParent() {
      this.$emit('updateParent', this.internalValue);
    },

    getProjects() {
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/user-projects`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.projects = response.data.map((ele) => ({
            value: ele.id,
            text: ele.Nombre,
            icon: ele.Icono,
            description: ele.descripcion,
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
